import { createContext, useContext, useEffect, useState } from 'react';
import type { ParentMenuItem } from '~/lib/strapi/types/parent-menu-item';
import type { FooterProps } from '~/modules/layout/components/nav/footer';
import type { TopBarProps } from '~/modules/layout/components/nav/navbar';

export const navContext = createContext<NavbarContext>({
  main: null,
  mobile: null,
  footer: null,
  topBar: null,
});

export type NavbarContext = {
  main: ParentMenuItem | null;
  mobile: ParentMenuItem | null;
  footer: FooterProps | null;
  topBar: TopBarProps | null;
};

type NavProviderProps = {
  nav: NavbarContext;
  children: any;
};

export default function NavProvider({ children, nav }: NavProviderProps) {
  const [context, setContext] = useState<NavbarContext>(nav);

  useEffect(() => {
    setContext(nav);
  }, [nav]);

  return <navContext.Provider value={context}>{children}</navContext.Provider>;
}

export function useNav() {
  const nav = useContext(navContext);
  if (!nav) {
    throw new Error('useNav must be used within a NavProvider');
  }

  return nav;
}
